// Script pour focus le champ recherche à l'ouverture de l'overlay
th_overlay.addCallbackOpen(overlayId => {
    if (overlayId === "overlay-search") {
        const input = document.querySelector("#overlay-search input[name='algolia-search']");

        if (input) {
            setTimeout(() => {

                input.focus();
            }, 250);
        }
    }
})