//(function () {

// Init des sliders
const slidersAncre = document.querySelectorAll('.js-slider-ancres');
let sliderAncre;        // instance de splide utililisé plus loin dans la lib th_ancres.js
slidersAncre.forEach(function (slider) {
    sliderAncre = initSlider(slider);
});

function initSlider(slider) {
    const splide = new Splide(slider, {
        padding: {left: 40, right: 50},           // espace pour les flèches de navigation
    }).mount();
    return splide;
}

//})();
