// JS qui concerne les single SIT
(function () {
    if (document.body.classList.contains('single-sit')) {       /* à changer en single-sit sur un vrai projet */


        // Init du slider des horaires
        const slidersHoraires = document.querySelectorAll('.js-slider-sit-horaires');
        slidersHoraires.forEach(slider => {
            const splide = new Splide(slider, {
                pagination: false,
            }).mount();

        });

        // Init du slider du bloc relation
        const slidersLabels = document.querySelectorAll('.js-slider-sit-labels');
        slidersLabels.forEach(slider => {
            const splide = new Splide(slider, {
                perMove: 4,
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                }
            }).mount();
        });

        // Init du slider du bloc relation
        let hasRelationChecked = false;
        const sliders = document.querySelectorAll('.js-slider-sit-relation');
        sliders.forEach(slider => {
            const splide = new Splide(slider, {
                breakpoints: {
                    600: {
                        arrows: false,
                    },
                }
            }).mount();
            const radioButton = document.querySelector(`input#${slider.getAttribute("id")}-radio`);

            if (radioButton) {
                radioButton.addEventListener("change", e => {
                    if (radioButton.checked) {
                        splide.refresh();
                    }
                });

                if (radioButton.getAttribute("checked") && !hasRelationChecked) {
                    radioButton.click();

                    hasRelationChecked = true;
                }
            }
        });


        // Boutons "Voir plus", quand texte tronqué sur les blocs "À propos"
        // ==========================================================================
        const blocViewMore = document.querySelectorAll('.js-view-more');
        blocViewMore.forEach(item => {
            const content = item.querySelector('[data-view-more-content]');
            const trigger = item.querySelector('[data-view-more-trigger]');

            // Comportement de viewmore sur le bloc si sa hauteur est suffisante
            if (content && content.getBoundingClientRect().height >= 100) {
                item.classList.add('view-more-active');

                trigger.addEventListener('click', function () {
                    item.classList.toggle('is-expanded');
                });
            }
        });

        th_video.init('.bloc-video');
    }

})();

