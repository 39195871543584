(function () {
    // Boutons "Voir plus"
    // ==========================================================================
    const cardViewMore = document.querySelectorAll('.js-view-more-bloc-relation-liste');
    cardViewMore.forEach(item => {
        const content = item.querySelector('[data-view-more-content]');
        const trigger = item.querySelector('[data-view-more-trigger]');
        const card = item.querySelectorAll('.card');

        if (content && card.length >= 6) {
            item.classList.add('view-more-active');

            trigger.addEventListener('click', function () {
                item.classList.toggle('is-expanded');
            });
        }
    });

})();