(function () {
    class BlocCarteInteractive {
        constructor(groupNode) {
            // Init du slider
            const slider = groupNode.querySelector('.js-slider-carte-interactive');
            this.splide = new Splide(slider, {
                //  drag: false
            }).mount();
            
            // Chaque hotspot de la map svg a un id type : id="zone1", "zone2", "poi1", "poi2"...
            // Chaque slide du slider a un attribut data-hotspot correspondant : data-hotspot="zone1", "zone2", "poi1", "poi2"...
            this.map = groupNode.querySelector('.svg-canvas');
            this.hotspots = this.map.querySelectorAll('#map-svg .zone, #map-svg .poi, #map-svg .ville');
            this.currentHotspot = "zone1";     // 1ere zone ou ville activée par défaut

            this.init();
        }

        init() {
            // Click sur un des hotspots de la map
            this.hotspots.forEach(hotspot => {
                hotspot.addEventListener('click', this.onClickHotspot.bind(this));
            });

            // Changement de slide -> active / désactive les hotspots
            this.splide.on('active', Slide => {
                this.currentHotspot = Slide.slide.dataset.hotspot;      // data-hotspot="zone1" ou "poi1"...
                this.setSelectedHotspot();
            });

            // Active la 1ere zone ou ville par défaut
            // Cherche la slide correspondant au hotspot (avec le data-hotspot="zoneXXX")
            const Slides = this.splide.Components.Slides.filter(`[data-hotspot="${this.currentHotspot}"]`);

            // Positionne le slider sur la slide
            if (Slides[0]) this.splide.go(Slides[0].index);

            this.setSelectedHotspot();
        }

        // Click sur un des hotspots de la map -> va au slide correspondant
        onClickHotspot(e) {
            const hotspot = e.currentTarget;
            const id = hotspot.id;      // id="zone1" ou "city1"...

            // Cherche la slide correspondant au hotspot (avec le data-hotspot="zoneXXX")
            const Slides = this.splide.Components.Slides.filter(`[data-hotspot="${id}"]`);

            // Positionne le slider sur la slide
            if (Slides[0]) this.splide.go(Slides[0].index);

            /*console.log('Slides', this.splide.Components.Slides);
            console.log('id', id);*/
        }

        // Active le hotspot courant / désactive les autres
        setSelectedHotspot() {
            this.hotspots.forEach((hotspot) => {
                if (hotspot.id === this.currentHotspot) {       // id="zone1" ou "city1"...
                    hotspot.classList.add('is-active');
                } else {
                    hotspot.classList.remove('is-active');
                }
            });
        }
    }

    // Init des blocs
    const blocs = document.querySelectorAll('.bloc-carte-interactive');
    blocs.forEach(function (bloc) {
        new BlocCarteInteractive(bloc);
    });

})();

