// Selectric est activé par défaut sur toutes les selects,
// mais nous on le veut pas avoir sur le sélecteur de langues en version tablette / mobile
$('.js-lang-dropdown-mobile').selectric('destroy');

// Ce script est nécessaire pour forcer le changement de page au click sur une option
$('.js-lang-dropdown-mobile').change(function () {
    var val = $(this).val();
    window.location.href = val;
});

// Languages selector
$('.js-lang-dropdown-desktop > a').on("click", function (e) {
    e.preventDefault();
    $(this).next().toggleClass("is-open");
});


function initNavigationBarre() {
    var st = $(this).scrollTop();
    var barrenNav = $(".js-isCover");

    if (st > 100) {
        barrenNav.addClass("--withbackground");
        barrenNav.removeClass("--transparent");

    } else {
        barrenNav.removeClass("--withbackground");
        barrenNav.addClass("--transparent");
    }
}

/* Detect the scroll of the page and animate the menu */
$(window).on('scroll', function () {
    initNavigationBarre();
});


$(document).ready(function () {
    initNavigationBarre();
});
