class OverlayReservationOpensystem {
    constructor(container) {
        this.container = container;
        this.btnPersInput = null;
        this.btnPersValue = 0;
        this.form = this.container.querySelector("form");
        this.tempSize = window.screen.width;
        this.panierHTML = '<span id="widget-panier"></span>';

        this.setPanier();
        this.initDateInput();
        this.initBtnPers();
        this.initSelectType();
        this.formSubmit();
    }

    setPanier() {
        // Gestion de l'endroit où mettre le panier js
        if(!$('#widget-panier').length > 0){
            if (window.screen.width < 980){
                $('.js-panier-navbarr-mobile').append(this.panierHTML);
            }else{
                $('.js-panier-navbarr').append(this.panierHTML);
            }
        }

        this.listenPanier();
    }

    listenPanier(){
        let size = null;
        //Déplacement du panier au resize de l'écran
        // =====
        addEventListener("resize", (e) => {
            let screenSize = window.screen.width;
            if (screenSize < 980){
                size = 'mobile';
            }else{
                size = 'desktop';
            }

            if((this.tempSize >= 980 && screenSize >= 980) || (this.tempSize < 980 && screenSize < 980)){
                //do nothing
            }else{
                this.updatePanier(size, 'add');
            }
            this.tempSize = screenSize;
        });


        //Déplacement du panier à l'ouverture/fermute des overlays
        // =====
        let cela = this;
        th_overlay.addCallbackOpen(function (overlay) {
            let size = null;
            if (window.screen.width < 980){
                size = 'mobile';
            }else{
                size = 'desktop';
            }

            if (overlay === "overlay-opensystem-reserver" || overlay === "overlay-opensystem-offrir") {
                cela.updatePanier(size, 'remove');
            }

            if (overlay === "overlay-reservation") {
                $("#widget-panier").appendTo("#overlay-reservation");
            }
        });

        th_overlay.addCallbackClose(function (overlay) {
            let size = null;
            if (window.screen.width < 980){
                size = 'mobile';
            }else{
                size = 'desktop';
            }

            cela.updatePanier(size, 'add');
        });
    }

    updatePanier(size, action){
        //Déplacement du panier selon deux critères : la taille de l'écran (mobile ou desktop) et l'action (effacer ou ajouter)
        this.panierHTML = $('#widget-panier')[0];
        if(action === "add"){
            if (size === "mobile"){
                if(!$('.js-panier-navbarr-mobile #widget-panier').length > 0){
                    $('.js-panier-navbarr-mobile').append(this.panierHTML);
                }
            }else if(size === "desktop"){
                if(!$('.js-panier-navbarr #widget-panier').length > 0){
                    $('.js-panier-navbarr').append(this.panierHTML);
                }
            }
        }else if(action === "remove"){
            $('.js-panier-navbarr #widget-panier, .js-panier-navbarr #widget-panier').remove();
        }

    }

    initDateInput() {
        /** Calendrier Formidable de base  */
        const calendarDateFormat = 'DD/MM/YYYY';
        const calendarDateLanguage = ($('html').attr('lang')).substring(0, 2);
        const startDate = moment(new Date()).format(calendarDateFormat);
        const dateContainer = this.container.querySelector("[data-jsgroupdate-os]");

        if (dateContainer) {
            const groupes_input_dates_os = dateContainer.getAttribute('data-jsgroupdate-os');

            if (groupes_input_dates_os) {
                const inputDates = dateContainer.querySelectorAll("input.date");

                if (inputDates.length) {
                    inputDates.forEach(input => {
                        const container = dateContainer.querySelector(".group-date");
                        const inline = true;

                        if (!isTouchDevice()) {
                            $(input).attr('type', 'text');
                            $(input).attr('placeholder', '');
                            $(input).dateRangePicker({
                                inline: inline,
                                stickyMonths: true,
                                singleDate: false,
                                alwaysOpen: false,
                                singleMonth: true,
                                showShortcuts: false,
                                showTopbar: false,
                                monthSelect: true,
                                hoveringTooltip: false,
                                selectForward: false,
                                yearSelect: [2022, 2030],
                                startOfWeek: 'monday',
                                startDate: startDate,
                                customArrowPrevSymbol: '<span class="icon-ico-arrow-left-slider"></span>',
                                customArrowNextSymbol: '<span class="icon-ico-arrow-right-slider"></span>',
                                format: calendarDateFormat,
                                language: calendarDateLanguage,
                                container: container,
                                separator: ' to ',
                                getValue: function () {
                                    if ($('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val() && $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val())
                                        return $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val() + ' to ' + $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val();
                                    else
                                        return '';
                                },
                                setValue: function (s, s1, s2) {
                                    $(this).parent().addClass("has-content");

                                    $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val(s1);
                                    $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').val(s2);
                                },
                            }).bind('datepicker-open', function (event, obj) {
                                $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').addClass('focus');
                            }).bind('datepicker-first-date-selected', function (event, obj) {
                                $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');
                                $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').addClass('focus');
                            }).bind('datepicker-close', function (event, obj) {
                                $('[data-jsdatestatus="to"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');
                                $('[data-jsdatestatus="from"]', '[data-jsgroupdate-os="' + groupes_input_dates_os + '"]').parent('.wrapper-date').removeClass('focus');

                            });
                        } else {
                            $(this).attr('type', 'date');
                        }
                    })
                }
            }
        }
    }

    initBtnPers() {
        const inputPers = this.container.querySelector(".opensystem_nbpers");

        const setValueBtnPers = (value) => {
            if (value < 0) {
                value = 0;
            }

            this.btnPersValue = value;
            this.btnPersInput.value = this.btnPersValue;
        }

        if (inputPers) {
            const btnMinus = inputPers.querySelector(".minus");
            const btnPlus = inputPers.querySelector(".plus");
            const input = inputPers.querySelector("[name='opensystem_nbpers']");
            const inputValue = parseInt(input.value);

            if (input && inputValue) {
                this.btnPersInput = input;
                this.btnPersValue = inputValue;

                btnMinus.addEventListener("click", () => setValueBtnPers(this.btnPersValue - 1));
                btnPlus.addEventListener("click", () => setValueBtnPers(this.btnPersValue + 1));
            }
        }
    }

    initSelectType() {
        const select = this.container.querySelector("#overlay-reservation-listing");

        if (select) {
            this.form.setAttribute("action", select.value);

            $(select).on("change", () => {
                const value = select.value;

                if (value) {
                    this.form.setAttribute("action", value);
                }
            })
        }
    }

    formSubmit() {
        if (this.form) {
            this.form.addEventListener('submit', (e) => {
                e.preventDefault();
                e.stopPropagation();

                const query = Array.from(this.form.querySelectorAll('input', this.form))
                    .filter((element) => {
                        if (
                            element.value !== '' &&
                            element.value !== 'empty' &&
                            element.getAttribute('name') !== 'year' &&
                            element.getAttribute('name') !== 'month' &&
                            element.getAttribute('name') !== 'y' &&
                            element.getAttribute('name') !== 'm'
                        ) {
                            return element.value;
                        }
                    })
                    .map((element) => `${encodeURIComponent(element.name)}=${encodeURIComponent(element.value)}`)
                    .join('&');
                const url = this.form.getAttribute('action');

                window.location.href = `${url}?${query}`;
            });
        }
    }
}

document.addEventListener("DOMContentLoaded", () => {
    const overlayOpenSystem = document.querySelectorAll(".js-onglet");
    if (overlayOpenSystem) {
        overlayOpenSystem.forEach(overlayOpenSystem => {
            new OverlayReservationOpensystem(overlayOpenSystem);
        })
    }
})