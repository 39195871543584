// OVERLAY SCROLLY
function callbackMapScrolly(macarte) {
    mscrolly.init(macarte);
    macarte.zoomControl.remove();
    macarte.dragging.disable();
    if (isTouchDevice()) {
        macarte.doubleClickZoom.disable();
    }
}


var mscrolly = {
    infosClass: ".js-coord-point",
    sectionClass: ".js-section",

    cardStep: ".js-card-step",
    defaultBtn: "default",
    newBtn: "hover",
    selectors: {
        page: '.js-scrolly-iti',
        map: '.js-scrolly-map',
        sliderClass: ".js-bloc-relation-scrolly",
        cardCaptionClass: "js-caption" // element sur lequel on va ajouter le data-letter à afficher sur la card dans le bloc relation
    },
    map: null,
    coords: [],
    markers: [],
    infoWindows: {},
    markersSlider: [],
    coordsSlider: [],
    cards: [],
    lastOnMarker: null,
    lastOnZoom: 12,
    lastSliderMarkers: [],
    sliderMarkerColor: 'in',
    stopClignote: false,
    visiblePoi: false,
    layerColor: "#76BE9B",
    layerWeight: 6,
    coordsTrace: [],
    gpxData: {
        coordLat: [],
        coordLng: [],
        coordAlt: []
    },
    lastPolylineDrawn: null,
    indices: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z"
    ],
    lastIndiceUsed: '',
    pictosTypes: {
        'pna': "icon-animal",
        'pcu': "icon-animal",
        'default': "icon-webcam",
    },

    init: function (map) {
        if (document.querySelector(mscrolly.selectors.page)) {
            mscrolly.map = map;
            mscrolly.initMarkers();
            mscrolly.initSliders();
            mscrolly.scrolly();
            mscrolly.sliders();
            mscrolly.initHoverSlider();

            if (window.location.href.indexOf("?checklatlng") > -1) {
                mscrolly.map.on('click', mscrolly.onMapClick);
            }
        }
    },

    initMarkers: function () {
        var el = document.querySelector(mscrolly.selectors.map, mscrolly.selectors.page);

        // créé un marker de base.
        if (el.getAttribute("data-lat") !== '') {
            var latLng = {
                lat: el.getAttribute('data-lat') * 1,
                lng: el.getAttribute('data-lng') * 1
            };

            if (latLng["lat"] !== 0 && latLng["lng"] !== 0) {
                mscrolly.markers[0] = th_maps.createMarker(mscrolly.map, latLng, mscrolly.newBtn);
            }
        }

        // trouve tous les points de la div mscrolly.infosClass
        document.querySelectorAll(mscrolly.infosClass).forEach(function (el) {
            mscrolly.createMarker(el.getAttribute('data-iteration') * 1, el);
        });
    },

    createMarker: function (id, el) {
        var latLng = {
            lat: el.getAttribute('data-lat') * 1,
            lng: el.getAttribute('data-lng') * 1
        };
        if (latLng["lat"] == 0 || latLng["lng"] == 0) {
            //on ne rajoute pas les points mal configuré
            return null;
        }
        mscrolly.coords[id] = el;
        mscrolly.markers[id] = th_maps.createMarker(mscrolly.map, latLng, mscrolly.defaultBtn);
        mscrolly.markers[id].on('click', mscrolly.goTo);
    },

    initSliders: function () {

        // petite sécurité si on a plus de cards que les index dispo
        var number_cards = document.querySelectorAll(mscrolly.selectors.sliderClass + ' .card').length;

        var number_indices = mscrolly.indices.length;
        if (number_indices < number_cards) {
            var diff = number_cards - number_indices;
            for (let i = 0; i <= diff; i++) {
                var new_val = number_indices + i;
                mscrolly.indices[new_val] = new_val;
            }
        }

        var els2 = document.querySelectorAll(mscrolly.selectors.sliderClass);
        els2.forEach(function (el) {
            var id = el.getAttribute("id");
            $("#" + id).find(".card").each(function (index, el) {

                var type = el.getAttribute('data-type');

                var caption = el.getElementsByClassName(mscrolly.selectors.cardCaptionClass)[0];
                if (caption) {
                    var indiceIndex = index;

                    if (mscrolly.lastIndiceUsed) {
                        var lastIndiceUsedIndex = mscrolly.getKeyByValue(mscrolly.indices, mscrolly.lastIndiceUsed);
                        indiceIndex = parseInt(lastIndiceUsedIndex) + 1;
                    }
                    var letter = mscrolly.indices[indiceIndex];
                    mscrolly.lastIndiceUsed = letter;
                    caption.setAttribute('data-letter', letter);
                    if (typeof mscrolly.pictosTypes[type] !== "undefined") {
                        var picto = mscrolly.pictosTypes[type];
                    } else {
                        var picto = mscrolly.pictosTypes["default"];
                    }

                    caption.setAttribute('data-icon', picto);
                }
            });
        });
    },

    onMapClick: function (e) {
        //spécial checklatlng pour aider les infos.
    },

    initHoverSlider: function () {
        // ajoute les evenements mouseenter et mouseleave aux th-slider
        $(".th-slider a").each(function (index, el) {
            $(this).mouseenter(function () {
                $("#" + $(this).data("slide-id")).addClass("sliderFlash");
            });
            $(this).mouseleave(function () {
                $("#" + $(this).data("slide-id")).removeClass("sliderFlash");
                mscrolly.sliderMarkerColor = "in";
            });
        });
    },


    goTo: function (info) {
        // scroll jusqu'au point cliqué sur la map.
        target = $("[data-lat='" + info.latlng.lat + "'][data-lng='" + info.latlng.lng + "']")
        $('html, body').animate({scrollTop: target.offset().top - (window.innerHeight / 2)}, 'slow');
    },

    scrolly: function () {
        // observeur sur la mscrolly.infosClass
        // dès qu'il est à 50% du viewport
        //  - Soit pas de latlng alors reset
        //  - Soit reset des icons et changement de l'icon cible + bound autour du point cible.
        var els = document.querySelectorAll(mscrolly.infosClass);

        var options = {
            threshold: 0.9
        }

        var callback = function (entries, observer) {
            for (var k in entries) {
                if (entries[k].isIntersecting || entries[k].isVisible) {
                    var latlng = {
                        lat: entries[k].target.getAttribute("data-lat") * 1,
                        lng: entries[k].target.getAttribute("data-lng") * 1
                    };
                    var id = entries[k].target.getAttribute('data-iteration') * 1;
                    var zoomLevel = entries[k].target.getAttribute("data-zoom") * 1;
                    if (latlng["lat"] == 0 && latlng["lng"] == 0) {
                        mscrolly.reset();
                    } else {
                        mscrolly.changeIcons(id, mscrolly.boundsMap(latlng, zoomLevel));
                        mscrolly.lastOnMarker = latlng;
                        mscrolly.lastOnZoom = zoomLevel;
                    }
                }
            }
        };

        var observer = new IntersectionObserver(callback, options);

        els.forEach(function (el) {
            observer.observe(el);
        })

        $(mscrolly.cardStep).each(function (id, el) {
            mscrolly.addCardStep(id, el);
        });
    },

    changeIcons: function (idCible) {
        //reset tous les icons
        for (marker in mscrolly.markers) {
            mscrolly.markers[marker].setIcon(th_maps.markersIcons[mscrolly.defaultBtn]);
        }

        //met le nouvel icon à la cible
        mscrolly.markers[idCible].setIcon(th_maps.markersIcons[mscrolly.newBtn]);
    },


    // card pour les step
    addCardStep: function (id, el) {
        latlng = {
            lat: el.getAttribute("data-lat") * 1,
            lng: el.getAttribute("data-lng") * 1
        };

        var newElcardStep = $(el);
        newElcardStep.find("img").attr('src', newElcardStep.find("img").attr('data-src')).removeClass("lazy");
        var cardStep_html = newElcardStep.get(0).outerHTML;

        // on enlève la class parsed-overlay pour pouvoir cliquer sur le btn qui ouvre l'overlay de l'étape
        cardStep_html = cardStep_html.replace("parsed-overlay", "");

        var icon = L.divIcon({
            className: 'icon',
            html: "<div></div>",
            iconSize: [30, 30],
            iconAnchor: [20, 20]
        });

        if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
            if (mscrolly.coordsSlider[id] == undefined) {
                mscrolly.coordsSlider[id] = newElcardStep;
                mscrolly.markersSlider[id] = th_maps.createMarker(mscrolly.map, latlng, icon).on('click', mscrolly.onMapClick);
                mscrolly.createInfoWindow(cardStep_html, mscrolly.markersSlider[id], 240);
            }
        }
    },

    createInfoWindow: function (content, marker, width) {
        if (!width) {
            width = 240;
        }

        width--;

        var infoWindow = L.popup({
            className: 'custom-popup-thuria',
            maxWidth: width,
            minWidth: width
        }).setContent(content);

        th_maps.infoWindows.push(infoWindow);

        if (marker) {
            marker.bindPopup(infoWindow);

            marker.addEventListener('click', function () {
                th_overlay.parse();
                infoWindow.openPopup();
            });
        }
    },

    boundsMap: function (latlng, zoom) {
        if (zoom == null) {
            zoom = 12;
        }
        //TODO rajouter une condition pour cibler le scrolly v1
        if (!mscrolly.visiblePoi) {
            mscrolly.map.flyTo(latlng, zoom);
        }
    },

    sliders: function () {
        // interseciton Observer pour le bloc-relation-scrolly
        // Sert à l'affichage des cards quand elles sont à l'écran.
        var els2 = document.querySelectorAll(mscrolly.selectors.sliderClass);

        var options2 = {
            // pour up quand la cible rentre et sort.
            // threshold: [0, 0.1]
            threshold: 1
        }

        var callback2 = function (entries2, observer) {
            for (var k in entries2) {
                var id = entries2[k].target.getAttribute("id");
                var slider = $('#' + id);
                var firstTick = false;
                if (slider.attr('data-visible') == null) {
                    slider.attr('data-visible', 'false');
                    firstTick = true;
                } else {
                    if (slider.attr('data-visible') == 'false') {
                        slider.attr('data-visible', 'true');
                    } else {
                        slider.attr('data-visible', 'false');
                    }
                }

                if (!firstTick) {
                    if (slider.attr('data-visible') == 'true') {
                        mscrolly.visiblePoi = true;
                        mscrolly.lastSliderMarkers = [];
                        $("#" + id).find(".card").each(function (index, el) {
                            var caption = el.getElementsByClassName(mscrolly.selectors.cardCaptionClass)[0];
                            if (caption) {
                                var letter = caption.getAttribute('data-letter');
                                var picto = '<i class="' + caption.getAttribute('data-icon') + '">';

                                mscrolly.addSliderMarker(id + index, el, letter, picto);
                            }
                        });

                        //on rajoute un flyBound
                        if (mscrolly.lastOnMarker) {
                            mscrolly.lastSliderMarkers.push(mscrolly.lastOnMarker);
                        }
                        var markerBounds = L.latLngBounds(mscrolly.lastSliderMarkers);
                        mscrolly.map.flyToBounds(markerBounds, {padding: [20, 20]});
                        //  /on rajoute un flyBound
                    } else {
                        mscrolly.visiblePoi = false;
                        $("#" + id).find(".card").each(function (index, el) {
                            mscrolly.removeSliderMarker(id + index);
                        });
                        //on remet le flyBound au bon endroit.
                        if (mscrolly.lastOnMarker) {
                            mscrolly.boundsMap(mscrolly.lastOnMarker, mscrolly.lastOnZoom);
                        }
                    }
                }
            }
        };

        var observer = new IntersectionObserver(callback2, options2);

        els2.forEach(function (el) {
            observer.observe(el);
        })
    },

    getKeyByValue: function (object, value) {
        return Object.keys(object).find(key => object[key] === value);
    },

    reset: function () {
        for (marker in mscrolly.markers) {
            mscrolly.markers[marker].setIcon(th_maps.markersIcons[mscrolly.defaultBtn]);
        }

        var latLngs = [];

        $(mscrolly.infosClass).each(function (index, el) {
            if (el.getAttribute("data-lat")) {
                latlng = {lat: el.getAttribute("data-lat") * 1, lng: el.getAttribute("data-lng") * 1};
                if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
                    latLngs.push(latlng);
                }
            }
        });


        //todo: $(".ltngDeBase")
        // recuperer les data lat lng
        // latLngs.push(latlng);


        var markerBounds = L.latLngBounds(latLngs);
        mscrolly.map.flyToBounds(markerBounds, {padding: [20, 20]});
    },

    addSliderMarker: function (id, el, letter, picto) {
        var latlng = {lat: el.getAttribute("data-lat") * 1, lng: el.getAttribute("data-lng") * 1};

        var newEl = $(el);
        newEl.find("img").attr('src', newEl.find("img").attr('data-src')).removeClass("lazy");
        var card_html = newEl.get(0).outerHTML;

        if (latlng["lat"] !== 0 && latlng["lng"] !== 0) {
            mscrolly.lastSliderMarkers.push(latlng);

            var icon = L.divIcon({
                className: 'blocpush-icon',
                html: "<div class='index-key' id='" + el.getAttribute("data-slide-id") + "'>" + letter + "</div><div class='typePicto'>" + picto + "</div>",
                iconSize: ["", ""],
                iconAnchor: [20, 20]
            });


            if (mscrolly.coordsSlider[id] == undefined) {
                //premiere création du marker.
                mscrolly.coordsSlider[id] = el;
                mscrolly.markersSlider[id] = th_maps.createMarker(mscrolly.map, latlng, icon).on('click', mscrolly.onMapClick);
                th_maps.createInfoWindow(card_html, mscrolly.markersSlider[id], 240);
            } else {
                //les markers ont déjà été créé on les remets dans le layer.
                mscrolly.addSliderMarkerSpe(latlng);
            }
        }
    },

    addSliderMarkerSpe: function (latlng) {
        //sous fonction pour rendre visible un point précis déjà existant.
        th_maps.markers.forEach(function (marker) {
            var latlng2 = marker.getLatLng();
            if (latlng2.lat == latlng["lat"] && latlng2.lng == latlng["lng"]) {
                mscrolly.map.addLayer(marker);
            }
        });
    },

    removeSliderMarker: function (id) {
        // sous fonction pour retirer un point visible sur la carte.
        if (mscrolly.markersSlider[id]) {
            mscrolly.map.removeLayer(mscrolly.markersSlider[id]);
        }
    },

};
