(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-bloc-temps-forts-manuel');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            omitEnd: false, // autant de dot que d'élément
            breakpoints: {
                600: {
                    arrows: false,
                },
            }
        }).mount();

        // récupère le nombre total de slide
        function countTotal() {
            const item = slider.querySelectorAll('.js-splide__slide');
            const itemsTotal = slider.querySelector('.js-splide__pagination__total');
            if (item.length < 10) {
                itemsTotal.textContent = "0" + item.length;
            } else {
                itemsTotal.textContent = item.length;
            }
        }

        // récupère l'index de la slide courrante
        function currentPagination() {
            const itemsCurrent = slider.querySelector('.js-splide__pagination__current');
            if (splide.index + 1 < 10) {
                itemsCurrent.textContent = "0" + String(splide.index + 1);
            } else {
                itemsCurrent.textContent = String(splide.index + 1);
            }
        }

        currentPagination();
        countTotal();

        splide.on('move', function (e) {
            currentPagination();
        });

    });


})();
