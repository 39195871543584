const btnScrollToComments = document.querySelectorAll('.js-scroll-to-comments');
if (btnScrollToComments){
    btnScrollToComments.forEach((elem) => {
        elem.addEventListener('click', (e) => {
            const scroller = document.querySelector('.overlay-commentaires .scroller');
            const commentsList = document.getElementById('comments-list');

            // elem
            setTimeout(() => {
                const rectScroller = scroller.getBoundingClientRect();
                const rectCommentsList = commentsList.getBoundingClientRect();

                scroller.scrollTop = rectCommentsList.top - rectScroller.top;
            }, "400");
        });
    });
}
