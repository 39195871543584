(function () {

    // Init des sliders
    const sliders = document.querySelectorAll('.js-slider-menu');
    sliders.forEach(slider => {
        const splide = new Splide(slider, {
            //    omitEnd: false, // autant de dot que d'élément
            wheel: true,
            releaseWheel: true,
            heightRatio: true,
            autoHeight: true,
            pagination: false,
            direction: 'ttb',
            breakpoints: {
                767: {
                    direction: 'ltr',
                },
                600: {
                    arrows: false,
                },
            }

        }).mount();
    });


})();
