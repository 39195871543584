// déplacement du panier si il doit apparaitre à différents endroits du HTML
// possible uniquement avec des overlays car l'html du panier ne doit être présent qu'une seule fois dans le code sinon marche pas
function clickFirstEl() {
    const firstBtn = document.querySelector("#overlay-reservation input.radio");

    if (firstBtn) {
        firstBtn.click();
    }
}

document.addEventListener("DOMContentLoaded", clickFirstEl);