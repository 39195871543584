(function () {

    const sliders = document.querySelectorAll('.js-slider-cover-home');
    sliders.forEach(slider => {
        var splide = new Splide('.js-slider-cover-home', {
            perPage: 1,
            pagination: true,
            // autoHeight: true,
            //autoWidth: true,
            trimSpace: false,
            focus: 'center',

            breakpoints: {
                600: {
                    arrows: false,
                },
            }

        }).mount();

        var thumbnails = document.getElementsByClassName('thumbnail');
        var current;


        for (var i = 0; i < thumbnails.length; i++) {
            initThumbnail(thumbnails[i], i);
        }


        function initThumbnail(thumbnail, index) {
            thumbnail.addEventListener('click', function () {
                splide.go(index);
            });
        }

        // récupère le nombre total de slide
        function countTotal() {
            const item = slider.querySelectorAll('.js-splide__slide');
            const itemsTotal = slider.querySelector('.js-splide__pagination__total');
            if (item.length < 10) {
                itemsTotal.textContent = "0" + item.length;
            } else {
                itemsTotal.textContent = item.length;
            }
        }

        // récupère l'index de la slide courrante
        function currentPagination() {
            const itemsCurrent = slider.querySelector('.js-splide__pagination__current');
            if (splide.index + 1 < 10) {
                itemsCurrent.textContent = "0" + String(splide.index + 1);
            } else {
                itemsCurrent.textContent = String(splide.index + 1);
            }
        }

        function currentThumbnail() {
            var thumbnail = thumbnails[splide.index];

            if (thumbnail) {
                if (current) {
                    current.classList.remove('is-active');
                }

                thumbnail.classList.add('is-active');
                current = thumbnail;
            }
        }

        currentPagination();
        countTotal();
        currentThumbnail();

        splide.on('mounted move', function () {
            currentPagination();
            currentThumbnail();
        });
    });

})();
